import { ISelectOption } from '~/core/ui/Select'

export const reminderOptions: ISelectOption[] = [
  {
    value: '15_mins',
    supportingObj: {
      name: '15 minutes before'
    }
  },
  {
    value: '30_mins',
    supportingObj: {
      name: '30 minutes before'
    }
  },
  {
    value: '1_hour',
    supportingObj: {
      name: '1 hour before'
    }
  },
  {
    value: '2_hours',
    supportingObj: {
      name: '2 hours before'
    }
  },
  {
    value: '12_hours',
    supportingObj: {
      name: '12 hours before'
    }
  },
  {
    value: '24_hours',
    supportingObj: {
      name: '24 hours before'
    }
  },
  {
    value: '48_hours',
    supportingObj: {
      name: '48 hours before'
    }
  }
]
